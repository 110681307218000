import React, { lazy, Suspense} from 'react'
// import MusicComp from 'components/MusicComp';
const MusicComp = lazy(() => import('components/MusicComp'))

export default function Music() {
  return (
    <div className="music-page">
      <Suspense fallback={<div>Loading...</div>}>
        <MusicComp />
      </Suspense>
    </div>
  )
}
