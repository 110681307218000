import React, { lazy, Suspense} from 'react';
import Landing from 'components/Landing';
// import ConcertsComp from 'components/ConcertsComp';
// import MusicComp from 'components/MusicComp';
// import Videos from 'components/Videos';
// import About from 'components/About';
import Fallback from 'components/Fallback';
const Videos = lazy(() => import('components/Videos'));
const MusicComp = lazy(() => import('components/MusicComp'));
const About = lazy(() => import('components/About'));
const ConcertsComp = lazy(() => import('components/ConcertsComp'));

export default function Home() {
  return (
    <div>
      {/* Landing, Main */}
      <Landing />
      {/* Concerts */}
      <Suspense fallback={<Fallback />}>
        <ConcertsComp />
      </Suspense>
      <Suspense fallback={<Fallback />}>
        <Videos />
      </Suspense>
      {/* Music */}
      <Suspense fallback={<Fallback />}>
        <MusicComp />
      </Suspense>
      {/* About */}
      <Suspense fallback={<Fallback />}>
        <About />
      </Suspense>
      {/* Instragram Feed */}
    </div>
  )
}
