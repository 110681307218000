import React from 'react'
import ConcertsComp from 'components/ConcertsComp'

export default function Concerts() {
  return (
    <div className="concerts-page">
      <ConcertsComp />
    </div>
  )
}
