import React from 'react'

export default function Landing() {

  const onVideoStart = () => {
    const videoSmall = document.querySelector('.landing__video-small');
    videoSmall.play();
    videoSmall.classList.add('landing__video-small--show');
    const imgSmall = document.querySelector('.landing__img-small');
    imgSmall.classList.add('landing__img-small--hide')
  }

  const onVideoEnd = () => {
    const videoSmall = document.querySelector('.landing__video-small');
    videoSmall.classList.remove('landing__video-small--show');
    const imgSmall = document.querySelector('.landing__img-small');
    imgSmall.classList.remove('landing__img-small--hide')
  }

  const onBigVideoStart = () => {
    const video= document.querySelector('.landing__video');
    video.classList.add('landing__video--show');
    const img= document.querySelector('.landing__img');
    img.classList.add('landing__img--hide')
  }

  const onBigVideoEnd = () => {
    const video= document.querySelector('.landing__video');
    video.classList.remove('landing__video--show');
    const img= document.querySelector('.landing__img');
    img.classList.remove('landing__img--hide')
  }

  return (
    <div className="landing">
      <div className="landing__heading">
        <h1 className="landing__title"> Ришат Тухватуллин</h1>
      </div>
      <div className="landing__video-wrapper">
        <img className="landing__img" src="photo_grey.jpg" alt="Rishat Tukhvatullin"/>
        <video className="landing__video"
          type="video/mp4"
          src="grey_back_short.mp4"
          playsInline={true}
          autoPlay
          muted
          onLoadedData={() => onBigVideoStart()}
          onEnded={() => onBigVideoEnd()}
        >
        </video>
        <img className="landing__img-small" src="photo_grey.jpg" alt="Rishat Tukhvatullin"/>
        <video className="landing__video-small"
          onLoadedData={() => onVideoStart()}
          onEnded={() => onVideoEnd()}
          // onCanPlay={() => {
          //   const videoSmall = document.querySelector('.landing__video-small');
          //   videoSmall.play();
          // }}
          playsInline
          autoPlay
          muted
          // loop
        >
          <source src="hd_cropped_image4.mp4" type="video/mp4" />
        </video>
      </div>

    </div>
  )
}
