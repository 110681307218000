import React from 'react';
import { Link } from 'react-router-dom';

export default function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const onLinkClick = () => setSidebarOpen(false);
  return (
    <div className={`sidebar ${sidebarOpen ? "sidebar--open" : ""}`}>
      <div className="sidebar__link">
        <Link onClick={() => onLinkClick()} to="/concerts">
          Концерты
        </Link>
      </div>
      <div className="sidebar__link">
        <Link onClick={() => onLinkClick()} to="/music">
          Музыка
        </Link>
      </div>
      <div className="sidebar__contancts">
        Связаться +79276785599
      </div>
    </div>
  )
}
